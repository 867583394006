import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { contents } from '/static/data/terms'
import Layout from 'layouts/index'
import Seo from 'components/seo'

const PrivacyPage = () => {
  const { t } = useTranslation()

  const Para = ({ children }) => {
    return <p className="mb-2 text-gray-600 text-base leading-[1.85]">{children}</p>
  }
  Para.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
  }
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('terms.meta.title'),
            // metaDescription: t('enterpriseSupportPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/terms/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className=" container pt-[5rem] pb-[6em]  leading-8" style={{ fontFamily: "'Noto Sans',sans-serif" }}>
          <div className="flex-1 text-center flex items-center flex-col px-[5%] md:px-[15%] pb-[120px]">
            <p className="pb-5 text-lg text-blue-200">{t('terms.title')}</p>
            <img
              className="h-12"
              src="https://www.mlytics.com/wp-content/uploads/2022/07/logo_Mlytics_word.svg"
              alt={t('terms.subTitle')}
            />
            <h1 className="leading-snug text-primary-500 text-4xl lg:text-[3em] w-full">{t('terms.subTitle')}</h1>
          </div>
          <div>
            <Para>{t('terms.description1')}</Para>
            <Para>{t('terms.description2')}</Para>
          </div>
          <ol className="text-gray-600 ml-[2.5rem] md:ml-[3.5rem] mt-6">
            {contents.map(({ content, title }, key) => (
              <div key={`cotent_${key}`} className="text-sm md:text-base leading-8">
                <div className="mb-1 relative text-gray-700 flex items-center">
                  <span className=" absolute left-[-20px] font-normal">{key + 1}.</span>
                  <div className="md:text-base text-sm font-bold">{t(title)}</div>
                </div>

                {content.map((paragraph, keyContent) => (
                  <p className=" mb-1 leading-[1.85]" key={`content${keyContent}`}>
                    {t(paragraph)}
                  </p>
                ))}
              </div>
            ))}
          </ol>
        </section>

        {/* <GotoDemoHero /> */}
      </React.Fragment>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
