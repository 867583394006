export const contents = [
  {
    title: 'terms.content1.title',
    content: ['terms.content1.paragraph1']
  },
  {
    title: 'terms.content2.title',
    content: [
      'terms.content2.paragraph1',
      'terms.content2.paragraph2',
      'terms.content2.paragraph3',
      'terms.content2.paragraph4'
    ]
  },
  {
    title: 'terms.content3.title',
    content: ['terms.content3.paragraph1']
  },
  {
    title: 'terms.content4.title',
    content: ['terms.content4.paragraph1', 'terms.content4.paragraph2', 'terms.content4.paragraph3']
  },
  {
    title: 'terms.content5.title',
    content: [
      'terms.content5.paragraph1',
      'terms.content5.paragraph2',
      'terms.content5.paragraph3',
      'terms.content5.paragraph4'
    ]
  },
  {
    title: 'terms.content6.title',
    content: ['terms.content6.paragraph1', 'terms.content6.paragraph2', 'terms.content6.paragraph3']
  },
  {
    title: 'terms.content7.title',
    content: ['terms.content7.paragraph1']
  },
  {
    title: 'terms.content8.title',
    content: ['terms.content8.paragraph1']
  },
  {
    title: 'terms.content9.title',
    content: [
      'terms.content9.paragraph1',
      'terms.content9.paragraph2',
      'terms.content9.paragraph3',
      'terms.content9.paragraph4',
      'terms.content9.paragraph5',
      'terms.content9.paragraph6'
    ]
  }
]
